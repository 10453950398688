import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({
  title = "Shower Cabin Website",
  description = "High-quality shower cabins and bathroom solutions",
  keywords = "shower cabin, bathroom, shower solutions",
  ogImage = "/logo.png",
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
    </Helmet>
  );
};

export default MetaTags;
